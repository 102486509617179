//
// components-rtl.scss
//

// UI Elements

.alert-dismissible {
  padding-left: $alert-dismissible-padding-r;
  padding-right: $alert-padding-x;
}

// search-box

.search-box{
  .form-control{
    padding-right: 40px;
    padding-left: 20px;
  }

  .search-icon{
    left: auto;
    right: 13px;
  }
}

// Breadcrumb

.breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-left: 0.5rem;
  padding-right: 0;
}

// progress

.custom-progress .progress-bar::before{
  right: auto;
  left: 2px;
}

// Invoice

@media print {
  .content-page,
  .content,
  body {
      margin-right: 0;
  }
}

// ribbon

.ribbon{
  left: -26px;
  right: auto;
  transform: rotate(-45deg);
}