//
// forms.scss
//

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left
      /*rtl: right*/
    ;
  }
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}


// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

.custom-checkbox {
  .selection-input-4 {
    width: $form-check-input-width;
    height: $form-check-input-width;
    margin-top: ($line-height-base - $form-check-input-width) * .5; // line-height minus check height
    vertical-align: top;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    border-radius: 0.25em;
    appearance: none;
    cursor: pointer;
    margin-bottom: 0;
    print-color-adjust: exact;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:checked[type="checkbox"] {
        background-color: $primary;
        border-color: $primary;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
}
// checkbox

.form-checkbox-outline {

  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg  !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg  !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '\F0765';
        font-family: "Material Design Icons";
        top: 6px !important;
        left: 4px;
        font-size: 6px;
      }
    }
  }
}



// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}


// Switch sizes

.form-switch-md {
  font-size: 20px;
  min-height: 26px;
  line-height: 26px;

  .form-check-label {
    font-size: $font-size-base;
    vertical-align: middle;
  }
}


.form-switch-lg {
  font-size: 26px;
  min-height: 36px;
  line-height: 36px;

  .form-check-label {
    font-size: $font-size-base;
    vertical-align: middle;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

// card radio

.card-radio {
  background-color: $card-bg;
  border: 2px solid $card-border-color;
  border-radius: $border-radius;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}


.card-radio-input {
  display: none;

  &:checked+.card-radio {
    border-color: $primary  !important;
  }
}


[data-bs-theme="dark"] {
  .form-switch {

    .form-check-input,
    .form-check-input:focus {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>"));
    }
  }
  .form-select {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-400}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }
}