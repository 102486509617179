// stylelint-disable declaration-no-important

//
// Text
//


// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-start   { text-align: right !important; }
    .text#{$infix}-end  { text-align: left !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

#sidebar-menu ul li .badge {
  margin-right: auto;
  margin-left: unset;
}